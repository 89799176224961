/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { AreaImage, AreaImageFromJSON, AreaImageFromJSONTyped, AreaImageToJSON } from "./AreaImage";

/**
 *
 * @export
 * @interface AreaImageControllerResponse
 */
export interface AreaImageControllerResponse {
  /**
   *
   * @type {AreaImage}
   * @memberof AreaImageControllerResponse
   */
  data?: AreaImage;
  /**
   *
   * @type {ErrorResponse}
   * @memberof AreaImageControllerResponse
   */
  error?: ErrorResponse;
}

export function AreaImageControllerResponseFromJSON(json: any): AreaImageControllerResponse {
  return AreaImageControllerResponseFromJSONTyped(json, false);
}

export function AreaImageControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AreaImageControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }

  return {
    data: !exists(json, "data") ? undefined : json["data"],
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function AreaImageControllerResponseToJSON(value?: AreaImageControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: AreaImageToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
