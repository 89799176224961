import React, { useEffect } from "react";

// Extend the Performance interface to include memory
declare global {
  interface Performance {
    memory?: {
      usedJSHeapSize: number;
      totalJSHeapSize: number;
      jsHeapSizeLimit: number;
    };
  }
}

const PerformanceMonitor: React.FC = () => {
  useEffect(() => {
    const logAndSetTitle = async () => {
      const currentTime = new Date().toLocaleTimeString();
      let heapUsage = "N/A";
      let cacheUsage = "N/A";

      // Check the JavaScript heap size
      if (performance.memory) {
        const { usedJSHeapSize, totalJSHeapSize } = performance.memory;
        const heapUsagePercent = ((usedJSHeapSize / totalJSHeapSize) * 100).toFixed(2);
        heapUsage = `${heapUsagePercent}% (${(usedJSHeapSize / 1048576).toFixed(2)} MB)`;
      }

      // Check cache storage size
      if (navigator.storage && navigator.storage.estimate) {
        try {
          const { usage = 0, quota = 1 } = await navigator.storage.estimate(); // Default values
          const cacheUsagePercent = ((usage / quota) * 100).toFixed(2);
          cacheUsage = `${cacheUsagePercent}% (${(usage / 1048576).toFixed(2)} MB)`;
        } catch (error) {
          console.error("Error retrieving cache size:", error);
        }
      }

      // Update the page title
      document.title = `Commissioning App | Heap: ${heapUsage} | Cache: ${cacheUsage}`;
      console.log(`--- Check occurred at: ${currentTime} ---`);
      console.log(`JavaScript Heap Usage: ${heapUsage}`);
      console.log(`Cache Usage: ${cacheUsage}`);
    };

    // Set an interval to check every 30 seconds
    const intervalId = setInterval(logAndSetTitle, 30000);

    // Initial run
    logAndSetTitle();

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []);

  return null; // This component doesn't render anything
};

export default PerformanceMonitor;
